import React, { useState, useEffect } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import _ from "lodash";

import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Card,
	Form,
	Input,
	Select,
	DatePicker,
	notification,
	Alert,
} from "antd";

import Swal from "sweetalert2";
import dayjs from "dayjs";
import { statuses } from "../Misc";
import Estados from "functions/states";

import moment from "moment";
import { useImmer } from "use-immer";

const { Content } = Layout;
const { Title } = Typography;

function Contract({ id }) {
	const [disabled, setDisabled] = useState(true);
	const [state, setState] = useImmer({});

	const [saveAction, { loading: loadingSave }] = useMutation(
		Queries.CONTRACT_SAVE
	);

	const { loading, data, error, refetch } = useQuery(Queries.CONTRACT, {
		fetchPolicy: "no-cache",
		variables: {
			uid: id,
		},
		onCompleted: (data) => {
			console.log(data);
			if (data) {
				setState({
					...data.Contract,
				});
			}
		},
	});

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		const { responsibility_start, signature_date, status } = state;

		const { data, error } = await saveAction({
			variables: {
				input: {
					responsibility_start,
					signature_date,
					status
				},
				uid: id || null,
			},
		});

		if (error) {
			ErrorDialog(
				"Não foi possível salvar as informações no momento. Tente novamente mais tarde."
			);
		} else {
			notification.success({
				message: "Sucesso",
				description: "Informações salvas com sucesso",
			});
			refetch();
		}
	};

	return (
		<Card
			title="Contrato"
			extra={
				<Button onClick={() => setDisabled((state) => !state)}>
					{disabled ? "Editar" : "Cancelar"}
				</Button>
			}
		>
			{loading ? <Loading /> : null}

			{!loading && error ? <Error refetch={refetch} /> : null}

			{state && !loading && !error ? (
				<Form layout="vertical">
					<Row gutter={20}>
						<Col span={24}>
							{!state.signature_date ? (
								<div style={{ marginBottom: "20px" }}>
									<Alert
										message="Não foi possível localizar o contrato. Por favor, defina as datas."
										type="error"
									/>
								</div>
							) : null}

							<Form.Item label="Data de Assinatura">
								<DatePicker
									format={"DD/MM/YYYY"}
									defaultValue={
										state?.signature_date ? moment(state.signature_date) : null
									}
									disabled={disabled}
									onChange={(e) => {
										setState((d) => {
											d.signature_date = e.toDate();
										});
									}}
									style={{
										width: "100%",
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label="Data de Início de Responsabilidade">
								<DatePicker
									defaultValue={
										state?.responsibility_start
											? moment(state.responsibility_start)
											: null
									}
									format={"MM/YYYY"}
									disabled={disabled}
									onChange={(e) => {
										setState((d) => {
											d.responsibility_start = e.toDate();
										});
									}}
									style={{
										width: "100%",
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label="Status">
								<Select
									value={state.status}
									disabled={disabled}
									onChange={(e) => {
										setState((d) => {
											d.status = e;
										});
									}}
								>
									<Select.Option value={"ongoing"}>Em homologação</Select.Option>
									<Select.Option value={"donot"}>Não homologar</Select.Option>
									<Select.Option value={"incomplete_data"}>Dados incompletos</Select.Option>
									<Select.Option value={"awaiting_client"}>Aguardando cliente</Select.Option>
									<Select.Option value={"done"}>Contrato Homologado</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						{!disabled ? (
							<Col span={24}>
								<Button
									type="primary"
									style={{ float: "right" }}
									loading={loadingSave}
									onClick={save}
								>
									Salvar
								</Button>
							</Col>
						) : null}
					</Row>
				</Form>
			) : null}
		</Card>
	);
}

export default Contract;
