import React, { useState } from "react";
import * as Queries from "./Queries";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";

import { Card, Col, Row, Layout, Typography, Divider, Badge } from "antd";

const { Content } = Layout;
const { Title } = Typography;

function Dashboard() {
	const { loading, data, error, refetch } = useQuery(Queries.DASHBOARD, {
		fetchPolicy: "no-cache",
	});

	const formatDecimal = (val) => {
		if (!val) return "-";
		return parseFloat(val).toLocaleString("pt-BR");
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div className="dashboard-view">
							<Title>Parceiros</Title>

							<Row gutter={16}>
								<Col span={6}>
									<Card size="small" title="Ativos">
										<h4>
											{formatDecimal(data.Dashboard?.partners?.Total) || "0"}
										</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card size="small" title="Novos (30 dias)">
										<h4>
											{formatDecimal(data.Dashboard?.partners?.Last_30days) ||
												"0"}
										</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card size="small" title="Novos (60 dias)">
										<h4>
											{formatDecimal(data.Dashboard?.partners?.Last_60days) ||
												"0"}
										</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card size="small" title="Novos (90 dias)">
										<h4>
											{formatDecimal(data.Dashboard?.partners?.Last_90days) ||
												"0"}
										</h4>
									</Card>
								</Col>
							</Row>

							<Divider />

							<Title>MEIs</Title>

							<Row gutter={16}>
								<Col span={6}>
									<Card size="small" title="Ativos">
										<h4>
											{formatDecimal(data.Dashboard?.users?.active) || "0"}
										</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card size="small" title="Aguardando Abertura">
										<h4>
											{formatDecimal(data.Dashboard?.users?.waiting_business) ||
												"0"}
										</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card size="small" title="Novos (30 dias)">
										<h4>
											{formatDecimal(data.Dashboard?.partners?.Last_30days) ||
												"0"}
										</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card size="small" title="Novos (60 dias)">
										<h4>
											{formatDecimal(data.Dashboard?.partners?.Last_60days) ||
												"0"}
										</h4>
									</Card>
								</Col>
							</Row>

							<Divider />

							<Title>Tickets</Title>

							<Row gutter={16}>
								<Col span={6}>
									<Card
										size="small"
										title={<div className="danger">Analisando</div>}
									>
										<h4>
											{formatDecimal(data.Dashboard?.tickets?.awaiting) || "0"}
										</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card
										size="small"
										title={<div className="info">Em andamento</div>}
									>
										<h4>
											{formatDecimal(data.Dashboard?.tickets?.in_progress) ||
												"0"}
										</h4>
									</Card>
								</Col>

								<Col span={6}>
									<Card size="small" title="Aguardando Cliente">
										<h4>
											{formatDecimal(
												data.Dashboard?.tickets?.waiting_customer
											) || "0"}
										</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card
										size="small"
										title={<div className="solved">Concluido</div>}
									>
										<h4>
											{formatDecimal(data.Dashboard?.tickets?.solved) || "0"}
										</h4>
									</Card>
								</Col>
							</Row>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>
			</Content>
		</Layout>
	);
}

export default Dashboard;
