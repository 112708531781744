import React, { useEffect, useState } from "react";

import { Menu, Layout, Badge, Button } from "antd";
import {
	AppstoreOutlined,
	WalletOutlined,
	FileOutlined,
	UserOutlined,
	MessageOutlined,
	StarOutlined,
	ShoppingOutlined,
	RocketOutlined,
	InsertRowLeftOutlined,
	YoutubeOutlined,
	CheckCircleOutlined,
} from "@ant-design/icons";

import { useQuery, gql } from "@apollo/client";
import { INVOICES } from "scenes/invoices/Queries";

import CertificatePurchase from "components/CertificatePurchase";

// import Logo from "assets/images/login-logo.png";
const { SubMenu } = Menu;
const { Sider } = Layout;

function View(props) {
	const [keys, setKeys] = useState(["/"]);

	const { data: badges, refetch: refetchBadges } = useQuery(
		gql`
	{
		AdminBadges {
			tickets
			invoices
		}
	}
	`,
		{
			fetchPolicy: "no-cache",
			pollInterval: 30 * 1000,
		}
	);

	window.refetchInvoicesBadge = refetchBadges;
	window.refetchBadges = refetchBadges;

	const handleClick = (e) => {
		props.history.push(e.key);
	};

	const setActiveRoute = () => {
		const { pathname } = props.history.location;
		if (pathname.indexOf("/user") > -1) {
			setKeys(["/users"]);
		} else if (pathname.indexOf("/partner") > -1) {
			setKeys(["/partners"]);
		} else {
			setKeys([pathname]);
		}
	};

	useEffect(() => {
		props.history.listen(() => {
			setActiveRoute();
		});
		setActiveRoute();
	}, []);

	return (
		<div>
			<Sider width={280} className={"sider"}>
				<div className="logo-wrapper">
					<img
						src={
							require(`themes/${process.env.REACT_APP_THEME}/images/login-logo.png`)
								.default
						}
						alt=""
					/>
				</div>

				<Menu
					onClick={handleClick}
					style={{ width: 280 }}
					defaultSelectedKeys={keys}
					selectedKeys={keys}
					defaultOpenKeys={["das-sub"]}
					mode="inline"
				>
					<Menu.Item icon={<AppstoreOutlined />} key={"/"}>
						Dashboard
					</Menu.Item>

					<Menu.Item icon={<ShoppingOutlined />} key={"/services"}>
						Serviços
					</Menu.Item>

					<Menu.Item icon={<MessageOutlined />} key={"/tickets"}>
						Tickets
						{badges?.AdminBadges?.tickets > 0 ? (
							<Badge
								count={badges?.AdminBadges?.tickets}
								style={{ marginLeft: 10 }}
							/>
						) : null}
					</Menu.Item>

					<Menu.Item icon={<UserOutlined />} key={"/users"}>
						MEIs
					</Menu.Item>

					<Menu.Item icon={<CheckCircleOutlined />} key={"/partners"}>
						Parceiros
					</Menu.Item>

					<Menu.Item icon={<WalletOutlined />} key={"/das"}>
						DAS
					</Menu.Item>

					<Menu.Item icon={<WalletOutlined />} key={"/das-installments"}>
						DAS de Parcelamento
					</Menu.Item>

					<Menu.Item icon={<WalletOutlined />} key={"/active-debts"}>
						Parcelamento Dívida Ativa
					</Menu.Item>

					<Menu.Item icon={<FileOutlined />} key={"/invoices"}>
						Notas Fiscais
						{badges?.AdminBadges?.invoices > 0 ? (
							<Badge
								count={badges?.AdminBadges?.invoices}
								style={{ marginLeft: 10 }}
							/>
						) : null}
					</Menu.Item>

					<Menu.Item icon={<YoutubeOutlined />} key={"/tutorials"}>
						Tutoriais
					</Menu.Item>

					<Menu.Item icon={<StarOutlined />} key={"/admins"}>
						Administradores
					</Menu.Item>

					<Menu.Item icon={<InsertRowLeftOutlined />} key={"/global-plans"}>
						Planos
					</Menu.Item>


					{process?.env?.REACT_APP_PROSPECTS === "1" ? (
						<Menu.Item icon={<RocketOutlined />} key={"/prospects"}>
							CRM Prospecção
						</Menu.Item>
					) : null}

					{process?.env?.REACT_APP_UAU === "1" ? (
						<Menu.Item icon={<RocketOutlined />} key={"/uau"}>
							UAU CRM Prospecção
						</Menu.Item>
					) : null}
				</Menu>

				<CertificatePurchase />
			</Sider>
		</div>
	);
}

export default View;
