import { gql } from "@apollo/client";

export const PARTNERS = gql`
  query Partners($filters: PartnersFilters, $page: Int!) {
    Partners(filters: $filters, page: $page) {
      partners {
        _id
        cnpj
        company_name
        name
        trade_name
        phone
        mobile
        email
        plan_id
        date_start
        meis
        status
        balance
        user_id
      }
      pages
      currentPage
      total
    }
  }
`;

export const PARTNER_GET = gql`
query Partner($id: String) {
  Partner(id: $id) {
    _id
    cnpj
    company_name
    trade_name
    name
    phone
    mobile
    email
    date_start
    date_end
    date_cancel
    contact_name
    plan_id
    global_plan
    partner_type
    status
    user_id
    app_url_android
    app_url_ios
    tutorials
    social_posts
    social_facebook
    social_instagram
    social_linkedin
    social_x
    social_tiktok
    website
    office_age
    employee_number
    meet_reference
    meet_reference_other
    financial_accountable
    number_contracted_cnpjs
    plan_value
    plan_conditions
    contract
    address {
      street
      zip_code
      number
      complement
      neighborhood
      city
      state
      municipal_code
    }
    address_store {
      street
      zip_code
      number
      complement
      neighborhood
      city
      state
      municipal_code
    }
  }
}
`;

export const PARTNER_SAVE = gql`
  mutation Partner($input: PartnerInput, $user_id: String) {
    Partner(input: $input, user_id: $user_id) {
      _id
      cnpj
      company_name
      trade_name
      phone
      mobile
      email
      date_start
      date_end
      date_cancel
      contact_name
      plan_id
      global_plan
      name
      user_id
      partner_type
      status
      app_url_android
      app_url_ios
      tutorials
      social_posts
      social_facebook
      social_instagram
      social_linkedin
      social_x
      social_tiktok
      website
      office_age
      employee_number
      meet_reference
      meet_reference_other
      financial_accountable
      number_contracted_cnpjs
      plan_value
      plan_conditions
      contract
      address {
        street
        zip_code
        number
        complement
        neighborhood
        city
        state
        municipal_code
      }
      address_store {
        street
        zip_code
        number
        complement
        neighborhood
        city
        state
        municipal_code
      }
    }
  }
`;

export const PARTNER_REMOVE = gql`
mutation PartnerRemove($id: String!) {
  PartnerRemove(id: $id)
}
`;

export const PARTNER_ACCESSES_GET = gql`
query PartnerAccesses($user_id: String) {
  PartnerAccesses(user_id: $user_id) {
    credentials_maquineta_login
    credentials_maquineta_value
    credentials_asaas_token
    asaas_webhook_status
    credentials_pagarme_apikey
    credentials_pagarme_password
    gateway_pix
	gateway_credit_card
	gateway_boleto
  }
}
`;
