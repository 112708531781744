import React, { useState, useEffect, useReducer, useRef } from "react";
import * as Queries from "./Queries";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";

import MaskPrice from "functions/mask/price";
import Mask from "functions/mask";
import PartnerSearch from "components/PartnerSearch";
import {Link} from "react-router-dom";

import {
	EditOutlined,
	MailOutlined,
	CloudOutlined,
	LockOutlined,
	MessageOutlined,
} from "@ant-design/icons";

import MaskedInput from "antd-mask-input";
import {
	Col,
	Row,
	Layout,
	Table,
	Badge,
	Typography,
	Button,
	Select,
	Form,
	Input,
	Popconfirm,
	notification,
	Popover,
	Tooltip,
	Pagination,
} from "antd";
import { statuses } from "./Misc";
import Import from "./components/Import";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

function Users(props) {
	const mounted = useRef(false);
	const [resendId, setResendId] = useState(null);
	const [welcomeEmail, setWelcomeEmail] = useState(null);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const [loadingPagination, setLoadingPagination] = useState(false);

	const [resetUserPass, { loading: loadingUserPass, data: dataUserPass }] =
		useMutation(Queries.USER_RESET_PASSWORD);
	const [
		welcomeEmailSend,
		{ loading: loadingWelcomeEmail, data: welcomeEmailData },
	] = useMutation(Queries.USER_WELCOME_EMAIL);

	const [load, { loading, data, error, refetch, called }] = useLazyQuery(
		Queries.USERS,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [filters, setFilters] = useState({
		status: "1",
	});

	const [pages, setPages] = useState({
		current: 1,
	});

	useEffect(() => {
		filter();
		mounted.current = true;
	}, []);

	useEffect(() => {
		if (mounted.current) {
			console.log(data);
		}
	}, [data]);

	useEffect(() => {
		if (dataUserPass) {
			if (dataUserPass?.UserResetPassword) {
				setResendId(null);
				notification.success({
					message: "E-mail de redefinição de senha enviado com sucesso!",
				});
			} else {
				notification.error({
					message: "Erro",
					description: "Não foi possível redefinir a senha no momento.",
				});
			}
		}
		if (welcomeEmailData) {
			if (welcomeEmailData?.UserResendWelcomeEmail) {
				setWelcomeEmail(false);
				notification.success({
					message: "E-mail enviado com sucesso!",
				});
			} else {
				notification.error({
					message: "Erro",
					description: "Não foi possível enviar o e-mail no momento.",
				});
			}
		}
	}, [dataUserPass, welcomeEmailData]);

	const filter = () => {
		setPages({ current: 1 });
		load({
			variables: {
				filters,
				page: 1,
			},
		});
	};

	const hasFilters = () => {
		let has = false;
		for (var prop in filters) {
			if (filters[prop] || filters[prop] !== "") has = true;
		}
		return has;
	};

	const clearFilters = () => {
		setPages({ current: 1 });
		setFilters({
			status: "1",
		});
		forceUpdate();
		load({
			variables: {
				page: 1,
			},
		});
	};

	const getFilterValue = (filters, path) => {
		return filters[path] || null;
	};

	const columns = [
		{
			title: "Nome",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Status MEI",
			dataIndex: "status",
			key: "status",
			width: 120,
			render: (status) => {
				return statuses[status];
			},
		},
		{
			title: "Status Homolog.",
			dataIndex: "contract_status",
			key: "contract_status",
			render: (value) => {
				if(value === "ongoing") return <Badge color="orange" text="Em homologação"/>
				if(value === "donot") return <Badge color="gray" text="Não homologar"/>
				if(value === "incomplete_data") return <Badge color="yellow" text="Dados incompletos"/>
				if(value === "awaiting_client") return <Badge color="orange" text="Dados incompletos"/>
				if(value === "done") return <Badge color="green" text="Contrato Homologado"/>
				return "-";
			}
		},
		{
			title: "NF Liberada",
			dataIndex: "invoice_released",
			key: "invoice_released",
			render: (value) => {
				if(value === "yes") return <Badge color="green" text="Sim"/>
				if(value === "no" || !value) return <Badge color="red" text="Não"/>
			}
		},
		{
			title: "Celular",
			dataIndex: "phone",
			key: "phone",
			render: (phone) => {
				return phone ? Mask(phone, "(99) 99999-9999") : "-";
			},
		},
		{
			title: "CNPJ",
			dataIndex: "cnpj",
			key: "cnpj",
			render: (cnpj) => cnpj || "-",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			render: (email) => email || "-",
		},
		{
			title: "Ações",
			dataIndex: "",
			key: "x",
			width: 200,
			render: (value, row) => {
				return (
					<div>
						<Tooltip title="Editar">
							<Button
								shape="circle"
								icon={<EditOutlined />}
								style={{ marginRight: 8 }}
								onClick={() => {
									props.history.push(`/user/${row._id}`);
								}}
							/>
						</Tooltip>

						<Tooltip title="Atendimento">
							<Button
								shape="circle"
								icon={<MessageOutlined />}
								style={{ marginRight: 8 }}
								onClick={() => {
									props.history.push(`/user/logs/${row._id}`);
								}}
							/>
						</Tooltip>

						<Tooltip title="Reenviar E-mail">
							<Popconfirm
								title="Reenviar e-mail de boas-vindas?"
								okText="Sim"
								cancelText="Não"
								visible={welcomeEmail === row._id ? true : false}
								onCancel={() => setWelcomeEmail(null)}
								okButtonProps={{ loading: loadingWelcomeEmail }}
								onConfirm={() => {
									welcomeEmailSend({
										variables: {
											id: row._id,
										},
									});
								}}
							>
								<Button
									shape="circle"
									icon={<MailOutlined />}
									style={{ marginRight: 8 }}
									onClick={() => setWelcomeEmail(row._id)}
								/>
							</Popconfirm>
						</Tooltip>

						<Tooltip title="Redefinir Senha">
							<Popconfirm
								title="Deseja redefinir a senha?"
								okText="Sim"
								cancelText="Não"
								visible={resendId === row._id ? true : false}
								onCancel={() => setResendId(null)}
								okButtonProps={{ loading: loadingUserPass }}
								onConfirm={() => {
									resetUserPass({
										variables: {
											id: row._id,
										},
									});
								}}
							>
								<Button
									shape="circle"
									icon={<LockOutlined />}
									onClick={() => setResendId(row._id)}
								/>
							</Popconfirm>
						</Tooltip>
					</div>
				);
			},
		},
	];

	console.log(data);

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>MEIs</Title>

					<div style={{ display: "flex" }}>
						<Import />
						<Button
							type="primary"
							style={{ marginLeft: 10 }}
							onClick={() => {
								props.history.push("/create-user");
							}}
						>
							Novo MEI
						</Button>
					</div>
				</div>

				<div style={{ marginBottom: "30px" }}>
					<Row gutter={20}>
						<Col flex="auto">
							<Row gutter={20}>
								<Col span={4}>
									<Form.Item>
										<Input
											placeholder="Nome"
											value={getFilterValue(filters, "name")}
											onChange={(e) => {
												const { value } = e.target;
												setFilters((state) => {
													return {
														...state,
														name: value,
													};
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={3}>
									<Form.Item>
										<MaskedInput
											mask={"111.111.111-11"}
											placeholder="Buscar por CPF"
											value={getFilterValue(filters, "cpf")}
											onChange={(e) => {
												const { value } = e.target;
												setFilters((state) => {
													return {
														...state,
														cpf: value,
													};
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={5}>
									<Form.Item>
										<MaskedInput
											mask={"11.111.111/1111-11"}
											placeholder="Buscar por CNPJ"
											value={getFilterValue(filters, "cnpj")}
											onChange={(e) => {
												const { value } = e.target;
												setFilters((state) => {
													return {
														...state,
														cnpj: value,
													};
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={4}>
									<Form.Item>
										<Input
											placeholder="Buscar por E-mail"
											value={getFilterValue(filters, "email")}
											onChange={(e) => {
												const { value } = e.target;
												setFilters((state) => {
													return {
														...state,
														email: value,
													};
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={4}>
									<Form.Item>
										<Select
											value={getFilterValue(filters, "status")}
											onChange={(e) => {
												setFilters((state) => {
													return {
														...state,
														status: e,
													};
												});
											}}
											placeholder={"Status"}
										>
											<Select.Option value="">Qualquer</Select.Option>
											<Select.Option value="0">Expirado</Select.Option>
											<Select.Option value="1">Ativo</Select.Option>
											<Select.Option value="2">
												Não cadastrou/requisitou empresa
											</Select.Option>
											<Select.Option value="3">
												Aguardando Abertura
											</Select.Option>
											<Select.Option value="4">Inativo</Select.Option>
											<Select.Option value="5">Cancelado</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={4}>
									<Form.Item>
										<PartnerSearch
											onChange={(e) => {
												setFilters((state) => {
													return {
														...state,
														partner_id: e,
													};
												});
											}}
											additionalProps={{
												allowClear: true,
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>
						<Col>
							<Button onClick={filter}>Filtrar</Button>

							{hasFilters() ? (
								<Button onClick={clearFilters} style={{ marginLeft: "10px" }}>
									Limpar
								</Button>
							) : null}
						</Col>
					</Row>
				</div>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data.Users.users.length ? data.Users.users : []}
								columns={columns}
								loading={loadingPagination}
								pagination={{
									defaultCurrent: data.Users.currentPage,
									total: data.Users.total,
									showSizeChanger: false,
									defaultPageSize: 10,
								}}
								onChange={async (pagination) => {
									setLoadingPagination(true);
									await refetch({
										page: pagination.current,
										filters,
									});
									setLoadingPagination(false);
								}}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>
			</Content>
		</Layout>
	);
}

export default Users;
