import React, { useState, useEffect } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import _ from "lodash";
import isEmail from "validator/lib/isEmail";
import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Card,
	Form,
	Input,
	Select,
	notification,
} from "antd";
import MaskedInput from "antd-mask-input";

import { statuses } from "../Misc";

const { Content } = Layout;
const { Title } = Typography;

function User({ id }) {
	const [disabled, setDisabled] = useState(true);
	const [state, setState] = useState(null);

	const { loading, data, error, refetch } = useQuery(Queries.USER_DOCUMENTS, {
		fetchPolicy: "no-cache",
		variables: {
			id,
		},
	});
	const [saveAction, { loading: loadingSave }] = useMutation(
		Queries.USER_DOCUMENTS_SAVE
	);

	useEffect(() => {
		console.log(data);
		if (data) setState(data.UserDocuments || {});
	}, [data]);

	const getValue = (path) => {
		return _.get(state, path);
	};

	const setValue = (path, value) => {
		setState((state) => {
			_.set(state, path, value);
			return state;
		});
	};

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		let obj = {
			...state,
		};

		const { data, error } = await saveAction({
			variables: {
				input: obj,
				uid: id,
			},
		});

		if (error) {
			ErrorDialog(
				"Não foi possível salvar as informações no momento. Tente novamente mais tarde."
			);
		} else {
			notification.success({
				message: "Sucesso",
				description: "Informações salvas com sucesso",
			});
			refetch();
		}
	};

	return (
		<Card
			title="Documentos da Pessoa Física"
			extra={
				<Button onClick={() => setDisabled((state) => !state)}>
					{disabled ? "Editar" : "Cancelar"}
				</Button>
			}
		>
			{loading ? <Loading /> : null}

			{!loading && error ? <Error refetch={refetch} /> : null}

			{state && !loading && !error ? (
				<Form layout="vertical">
					<Row gutter={20}>
						<Col span={8}>
							<Form.Item name="birth_date" label="Data de Nascimento">
								<MaskedInput
									disabled={disabled}
									mask={"11/11/1111"}
									defaultValue={getValue("birth_date")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("birth_date", value);
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="voter_title" label="Título de Eleitor">
								<Input
									disabled={disabled}
									defaultValue={getValue("voter_title")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("voter_title", value);
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="tax_code" label="Número Imposto de Renda">
								<Input
									disabled={disabled}
									defaultValue={getValue("tax_code")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("tax_code", value);
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="rg" label="RG">
								<Input
									disabled={disabled}
									defaultValue={getValue("rg")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("rg", value);
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="expeditor" label="Orgão Expeditor">
								<Input
									disabled={disabled}
									defaultValue={getValue("expeditor")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("expeditor", value);
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="expedition_date" label="Data de Expedição">
								<MaskedInput
									disabled={disabled}
									mask={"11/11/1111"}
									defaultValue={getValue("expedition_date")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("expedition_date", value);
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="mother_name" label="Nome da Mãe">
								<Input
									disabled={disabled}
									defaultValue={getValue("mother_name")}
									onChange={(e) => {
										const { value } = e.target;
										setValue("mother_name", value);
									}}
								/>
							</Form.Item>
						</Col>

						{!disabled ? (
							<Col span={24}>
								<Button
									type="primary"
									style={{ float: "right" }}
									onClick={save}
									loading={loadingSave}
								>
									Salvar
								</Button>
							</Col>
						) : null}
					</Row>
				</Form>
			) : null}
		</Card>
	);
}

export default User;
