import { gql } from "@apollo/client";

export const USERS = gql`
  query Users($filters: UserListFilters, $page: Int!) {
    Users(filters: $filters, page: $page) {
      users {
        _id
        email
        name
        last_name
        civil_status
        gender
        cpf
        cnpj
        phone
        status
        plan
        partner_company {
          name
        }
        gov_br_password
        external_password
        certificate_password  
        partner_id
        partner_name
        contract_status
        invoice_released
      }
      pages
      currentPage
      total
    }
  }
`;

export const USER = gql`
query User($id: String!) {
  User(uid: $id) {
    email
    name
    last_name
    civil_status
    gender
    cpf
    phone
    status
    plan
    iugu_customer_id
    iugu_subscription_id
    plan
    notes
    partner_id
    partner_plan
    partner_name
    gov_br_password
    external_password
    certificate_password
    certificate_url
    invoice_released
  }
}
`;

export const USER_SAVE = gql`
mutation User($input:UserInput!) {
  User(input:$input) {
    _id
    email
    name
    cpf
    phone
    status
    plan
    updatedAt
    createdAt
    iugu_customer_id
    iugu_subscription_id
    plan
    partner_id
    partner_plan
    partner_name
    gov_br_password
  }
}
`;

export const BUSINESS = gql`
  query Business($id: String!) {
    Business(uid: $id) {
      cnpj
      company_name
      trade_name
      email
      access_code
      municipal_registration
      activity
      state_registration
      real_estate_registration
      inactivation_date
      ctn
    }
  }
`;

export const BUSINESS_SAVE = gql`
mutation Business($input: BusinessInput!, $uid: String) {
  Business(uid: $uid, input: $input) {
    _id
  }
}
`;

export const ADDRESS = gql`
query AddressByUser($id: String!) {
  AddressByUser(id: $id) {
    addressable_type
    street
    zip_code
    number
    complement
    neighborhood
    city
    state
    municipal_code
  }
}
`;

export const ADDRESS_SAVE = gql`
mutation AddressByUser($input: AddressInput!, $id: String!) {
  AddressByUser(input: $input, id:$id) {
    _id
  }
}
`;

export const CONTRACT = gql`
query Contract($uid: String!) {
  Contract(uid: $uid) {
    responsibility_start
    signature_date
    status
  }
}
`;

export const CONTRACT_SAVE = gql`
mutation Contract($uid: String!, $input: ContractInput!) {
  Contract(uid: $uid, input: $input) {
    responsibility_start
    signature_date
  }
}
`;

export const DOCUMENTS = gql`
query Business($id: String!) {
  Business(uid: $id) {
    documents {
        alvara {
            name
            path
        }
        cartao_cnpj {
            name
            path
        }
        certificado_mei {
            name
            path
        }
        rg {
            name
            path
        }
        cpf {
            name
            path
        }
        cnh {
            name
            path
        }
        titulo_eleitor {
            name
            path
        }
        iptu {
            name
            path
        }
        dasn_simei {
            name
            path
        }
    }
  }
}
`;

export const DOCUMENT_UPLOAD = gql`
mutation BusinessDocument($input: BusinessDocumentInput!, $uid: String!) {
  BusinessDocument(input: $input, uid: $uid) {
      name
      path
      type
      key
  }
}
`;

export const CREATE_USER = gql`
mutation CreateUser($input:CreateUserInput!) {
  CreateUser(input:$input) {
    result
    error
  }
}
`;

export const USER_DOCUMENTS = gql`
query UserDocuments($id: String!) {
  UserDocuments(uid: $id) {
    mother_name
    rg
    birth_date
    voter_title
    tax_code
    expeditor
    expedition_date
  }
}
`;

export const USER_DOCUMENTS_SAVE = gql`
mutation UserDocuments($input: CreateUserDocuments!, $uid: String!) {
  UserDocuments(input: $input, uid: $uid) {
    mother_name
    rg
    birth_date
    voter_title
    tax_code
    expeditor
    expedition_date
  }
}
`;

export const USER_RESET_PASSWORD = gql`
mutation UserResetPassword($id: String!) {
  UserResetPassword(id: $id)
}
`;

export const USER_WELCOME_EMAIL = gql`
mutation UserResendWelcomeEmail($id: String!) {
  UserResendWelcomeEmail(id: $id)
}
`;

export const LOGS_SAVE = gql`
mutation UsersLog($input: UserLogInput!) {
  UsersLog(input: $input)
}
`;

export const LOGS_GET = gql`
query UsersLogs($id: String!) {
  UsersLogs(id: $id) {
    content
    createdAt
    userName
    author
  }
}
`;

export const USER_IMPORT = gql`
  mutation UserImport($file: Upload!, $partner: String) {
    UserImport(file: $file, partner: $partner) {
      result
      error
      notCreated
    }
  }
`;

export const USER_REMOVE = gql`
  mutation UserRemove($id: String!) {
    UserRemove(id: $id)
  }
`;
