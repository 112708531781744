import {
	Layout,
	Typography,
	Row,
	Col,
	Upload,
	Button,
	Form,
	Input,
	Divider,
	Collapse,
	DatePicker,
	Select,
	notification,
} from "antd";
import { useImmer } from "use-immer";
import { CloudUploadOutlined } from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from "moment";
import { gql, useMutation, useQuery } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import { FilePdfOutlined } from "@ant-design/icons";
import { PlusOutlined, UploadOutlined, FileOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title } = Typography;

function PartnerPlans(props) {
	const { id } = props.match.params;

	const [state, setState] = useImmer({
		briefing: {},
	});
	const [logo, setLogo] = useImmer(null);
	const [logoType, setLogoType] = useImmer(null);

	const [saveAction, saveActionRequest] = useMutation(
		gql`
		mutation PartnerKickoff($input: PartnerKickOffInput, $partner_id: String!) {
			PartnerKickoff(input: $input, partner_id: $partner_id)
		}
	`,
		{
			onCompleted: (res) => {},
		}
	);

	const request = useQuery(
		gql`
		query PartnerKickoff($partner_id: String!) {
			PartnerKickoff(partner_id: $partner_id) {
				logo
				logo_type
				webapp
				url
				domain
				appointments
				implanting
				app_ios
				app_android
				deliveryDate
				paymentMethod
				implanting
				no_payment
				user_signup_status
				briefing_file_url
				date_onboarding
				privacy_policy
				terms_of_use
				gtm_id
				observations
				briefing {
					banner
					about_us
					product_services
					plans
					contact
					testimonials
				}
			}
		}
	`,
		{
			variables: {
				partner_id: id,
			},
			fetchPolicy: "no-cache",
			onCompleted: (response) => {
				if (response.PartnerKickoff) {
					const { logo_type, ...data } = response.PartnerKickoff;
					if (logo_type) setLogoType(logo_type);

					let res = { ...data };
					if (res.deliveryDate) res.deliveryDate = moment(res.deliveryDate);
					if (res.date_onboarding)
						res.date_onboarding = moment(res.date_onboarding);
					setState({
						...res,
						briefing: {
							...res.briefing,
						},
					});
				}
			},
		}
	);

	if (request.loading) {
		return <Loading />;
	}

	if (request.error) {
		return (
			<Error
				refetch={() => {
					request.refetch({
						variables: { partner_id: id },
					});
				}}
			/>
		);
	}

	const save = async () => {
		const { logo: currentLogo, briefing_file_url, ...data } = state;
		const payload = { ...data };

		if (payload.deliveryDate)
			payload.deliveryDate = payload.deliveryDate.toISOString();
		if (payload.date_onboarding)
			payload.date_onboarding = payload.date_onboarding.toISOString();
		if (logo) payload.logo = logo;

		try {
			let res = await saveAction({
				variables: {
					input: payload,
					partner_id: id,
				},
			});

			if (res?.data?.PartnerKickoff) {
				notification.success({
					message: "Informações atualizadas com sucesso",
				});
				request.refetch();
				setLogo(null);
			} else {
				notification.error({
					message: "Erro ao atualizar informações",
				});
			}
		} catch (error) {
			notification.error({
				message: "Erro ao atualizar informações",
			});
		}
	};

	console.log(state);

	return (
		<div style={{ paddingTop: 20 }}>
			<Row gutter={40}>
				<Col span={12}>
					<h2 className="text-primary">Informações</h2>
					<Divider />

					<Row gutter={20}>
						<Col span={24}>
							<Row gutter={30}>
								<Col flex={"300px"}>
									<span style={{ paddingBottom: 10, display: "block" }}>
										Logo
									</span>
									<Upload
										className="logo-upload"
										beforeUpload={() => false}
										onChange={(e) => {
											const { file } = e;
											setLogo(file);
											setLogoType(file.type);
										}}
										fileList={[]}
										accept="image/png,image/jpg,image/gif,application/pdf"
									>
										<div className="image">
											{state.logo && (
												<div className="upload-icon">
													<CloudUploadOutlined size={20} />
												</div>
											)}

											{!logo && state.logo ? (
												<>
													{logoType !== "application/pdf" ? (
														<>
															<img src={state.logo} alt="" />
														</>
													) : (
														<div
															style={{
																display: "flex",
																flexDirection: "column",
																textAlign: "center",
															}}
														>
															<FilePdfOutlined
																style={{ fontSize: 80, opacity: 0.3 }}
															/>
															<span style={{ paddingTop: 10 }}>PDF</span>
														</div>
													)}
												</>
											) : null}
											{logo ? (
												<>
													{logoType !== "application/pdf" ? (
														<>
															<img src={URL.createObjectURL(logo)} alt="" />
															<div className="upload-icon">
																<CloudUploadOutlined size={20} />
															</div>
														</>
													) : (
														<div
															style={{
																display: "flex",
																flexDirection: "column",
																textAlign: "center",
															}}
														>
															<FilePdfOutlined
																style={{ fontSize: 80, opacity: 0.3 }}
															/>
															<span style={{ paddingTop: 10 }}>PDF</span>
														</div>
													)}
												</>
											) : null}
											{!logo && !state.logo && (
												<div className="no-logo">
													<CloudUploadOutlined size={30} />
												</div>
											)}
										</div>
									</Upload>

									{state.logo && (
										<div style={{ marginBottom: 20 }}>
											<Button
												onClick={() => {
													window.open(state.logo);
												}}
											>
												Ver arquivo
											</Button>
										</div>
									)}
								</Col>
								<Col flex="1">
									<Form layout="vertical">
										<Form.Item label="Previsão de Entrega">
											<DatePicker
												style={{ width: "100%" }}
												onChange={(e) => {
													setState((d) => {
														d.deliveryDate = e;
													});
												}}
												format={"DD/MM/YYYY"}
												value={state?.deliveryDate}
											/>
										</Form.Item>

										<Form.Item label="Domínio">
											<Input
												onChange={(e) => {
													setState((d) => {
														d.domain = e.target.value;
													});
												}}
												value={state?.domain}
											/>
										</Form.Item>

										<Form.Item label="Webapp">
											<Input
												onChange={(e) => {
													setState((d) => {
														d.webapp = e.target.value;
													});
												}}
												value={state?.webapp}
											/>
										</Form.Item>

										<Form.Item label="Site">
											<Input
												onChange={(e) => {
													setState((d) => {
														d.url = e.target.value;
													});
												}}
												value={state?.url}
											/>
										</Form.Item>
									</Form>
								</Col>
							</Row>
						</Col>
						<Col flex="auto">
							<Form layout="vertical">
								<Row>
									<Col span={8}>
										<Form.Item label="Apontamentos">
											<Checkbox
												onChange={(e) => {
													setState((d) => {
														d.appointments = e.target.checked;
													});
												}}
												checked={state?.appointments}
											/>
										</Form.Item>
									</Col>

									<Col span={8}>
										<Form.Item label="Sem pagamento">
											<Checkbox
												onChange={(e) => {
													setState((d) => {
														d.no_payment = e.target.checked;
													});
												}}
												checked={state?.no_payment}
											/>
										</Form.Item>
									</Col>

									<Col span={8}>
										<Form.Item label="Implantação">
											<Checkbox
												onChange={(e) => {
													setState((d) => {
														d.implanting = e.target.checked;
													});
												}}
												checked={state?.implanting}
											/>
										</Form.Item>
									</Col>
								</Row>

								<Form.Item label="App iOS">
									<Input
										onChange={(e) => {
											setState((d) => {
												d.app_ios = e.target.value;
											});
										}}
										value={state?.app_ios}
									/>
								</Form.Item>
								<Form.Item label="App Android">
									<Input
										onChange={(e) => {
											setState((d) => {
												d.app_android = e.target.value;
											});
										}}
										value={state?.app_android}
									/>
								</Form.Item>
								<Form.Item label="URL Política de Privacidade">
									<Input
										onChange={(e) => {
											setState((d) => {
												d.privacy_policy = e.target.value;
											});
										}}
										value={state?.privacy_policy}
									/>
								</Form.Item>
								<Form.Item label="URL Termos de Uso">
									<Input
										onChange={(e) => {
											setState((d) => {
												d.terms_of_use = e.target.value;
											});
										}}
										value={state?.terms_of_use}
									/>
								</Form.Item>
								<Form.Item label="ID Google Tag Manager">
									<Input
										onChange={(e) => {
											setState((d) => {
												d.gtm_id = e.target.value;
											});
										}}
										value={state?.gtm_id}
									/>
								</Form.Item>
								<Form.Item label="Meios de Pagamento">
									<Select
										mode="multiple"
										onChange={(e) => {
											setState((d) => {
												d.paymentMethod = e;
											});
										}}
										value={state.paymentMethod ?? []}
										options={[
											"Cora",
											"Maquineta Virtual",
											"PagSeguro",
											"Mercado Pago",
											"Asaas",
											"Cielo",
											"Faturagil",
											"Zoop",
											"Stripe",
										].map((i) => {
											return {
												label: i,
												value: i,
											};
										})}
									/>
								</Form.Item>
								<Row gutter={20}>
									<Col span={12}>
										<Form.Item label="Data Onboarding">
											<DatePicker
												style={{ width: "100%" }}
												onChange={(e) => {
													setState((d) => {
														d.date_onboarding = e;
													});
												}}
												format={"DD/MM/YYYY"}
												value={state?.date_onboarding}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Status de novo usuário">
											<Select
												onChange={(e) => {
													setState((d) => {
														d.user_signup_status = e;
													});
												}}
												value={state.user_signup_status || 4}
											>
												<Select.Option value={1}>Ativo</Select.Option>
												<Select.Option value={4}>Inativo</Select.Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>
								<Form.Item label="Observações">
									<Input.TextArea
										rows={6}
										onChange={(e) => {
											setState((d) => {
												d.observations = e.target.value;
											});
										}}
										value={state?.observations}
									/>
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<h2 className="text-primary">Briefing</h2>
					<Divider />

					<Form layout="vertical">
						<Form.Item label="Arquivo Briefing">
							<div
								style={{
									display: "flex",
									gap: 10,
								}}
							>
								<Upload
									beforeUpload={() => false}
									onChange={(e) => {
										const { file } = e;
										setState((draft) => {
											draft.briefing_file = file;
										});
									}}
									fileList={state?.briefing_file ? [state.briefing_file] : []}
									accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
								>
									<Button icon={<UploadOutlined />}>Enviar arquivo</Button>
								</Upload>
								{state?.briefing_file_url && (
									<Button
										type="primary"
										onClick={() => {
											window.open(state.briefing_file_url);
										}}
									>
										Ver arquivo
									</Button>
								)}
							</div>
						</Form.Item>
					</Form>

					<Collapse>
						<Collapse.Panel header="Banner Principal" key="1">
							<Input.TextArea
								rows={6}
								onChange={(e) => {
									setState((d) => {
										d.briefing.banner = e.target.value;
									});
								}}
								value={state?.briefing?.banner}
							/>
						</Collapse.Panel>
						<Collapse.Panel header="Seção Sobre Nós" key="2">
							<Input.TextArea
								rows={6}
								onChange={(e) => {
									setState((d) => {
										d.briefing.about_us = e.target.value;
									});
								}}
								value={state?.briefing?.about_us}
							/>
						</Collapse.Panel>
						<Collapse.Panel header="Seção Produto/Serviços" key="3">
							<Input.TextArea
								rows={6}
								onChange={(e) => {
									setState((d) => {
										d.briefing.product_services = e.target.value;
									});
								}}
								value={state?.briefing?.product_services}
							/>
						</Collapse.Panel>
						<Collapse.Panel header="Seção Planos" key="4">
							<Input.TextArea
								rows={6}
								onChange={(e) => {
									setState((d) => {
										d.briefing.plans = e.target.value;
									});
								}}
								value={state?.briefing?.plans}
							/>
						</Collapse.Panel>
						<Collapse.Panel header="Seção Contato/Fale Conosco" key="5">
							<Input.TextArea
								rows={6}
								onChange={(e) => {
									setState((d) => {
										d.briefing.contact = e.target.value;
									});
								}}
								value={state?.briefing?.contact}
							/>
						</Collapse.Panel>
						<Collapse.Panel header="Seção Depoimentos" key="6">
							<Input.TextArea
								rows={6}
								onChange={(e) => {
									setState((d) => {
										d.briefing.testimonials = e.target.value;
									});
								}}
								value={state?.briefing?.testimonials}
							/>
						</Collapse.Panel>
					</Collapse>
				</Col>
			</Row>
			<Divider />
			<div>
				<Button
					onClick={() => {
						save();
					}}
					type="primary"
					loading={saveActionRequest.loading}
					disabled={saveActionRequest.loading}
					style={{
						float: "right",
					}}
				>
					Salvar
				</Button>
			</div>
		</div>
	);
}

export default PartnerPlans;
