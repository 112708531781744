import React, { useState, useEffect, useReducer } from "react";
import { Button, Modal, Upload, Form, Alert } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import PartnerSearch from "components/PartnerSearch";

import Swal from "sweetalert2";

import * as Queries from "../Queries";
import { useMutation } from "@apollo/client";

export default function Import({}) {

  
  const [saveAction] = useMutation(
    Queries.USER_IMPORT
  );


  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [partner, setPartner] = useState(null);
  const [notCreated, setNotCreated] = useState([]);


  const save = async () => {


      if(!fileList.length) {
            return Swal.fire({
                  title: "Erro",
                  text: "Selecione um arquivo para importar",
                  icon: "error"
            });
      }

      setNotCreated([]);
      setLoading(true);

      const { data, error } = await saveAction({
        variables: {
          file: fileList[0].originFileObj,
          partner,
        },
      });

      setLoading(false);

      if(data.UserImport.result) {

         if(data.UserImport.notCreated.length) {
            setNotCreated(data.UserImport.notCreated);
         }
         Swal.fire({
            title: "Sucesso",
            text: !data.UserImport.notCreated.length ? "Importação realizada com sucesso" : "Importação realizada com sucesso, mas não foi possível criar alguns usuários.",
            icon: "success"
         }); 

      } else {
         Swal.fire({
            title: "Erro",
            text: data.UserImport.error,
            icon: "error"
         });
      }



  }

  return (
    <div>
      <Button type="default" onClick={() => setVisible(true)}>
        Importar
      </Button>

      <Modal
        title="Importar MEIs"
        visible={visible}
        onOk={() => {
          save();
        }}
        onCancel={() => {
          setVisible(false);
          setNotCreated([]);
          setFileList([]);
        }}
        footer={[
          <Button
            key="back"
            loading={loading}
            onClick={() => {
              if(!loading) {
                setVisible(false);
              }
            }}
          >
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              if(!loading) {
                save();
              }
            }}
          >
            Importar
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Arquivo CSV">
            <Upload
              accept={"text/csv"}
              customRequest={(data) => {
                console.log(data);
              }}
              onChange={({ fileList }) => {
                fileList[0].status = "done";
                setFileList(fileList);
              }}
              fileList={fileList}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Parceiro">
            <PartnerSearch
              onChange={(partner) => {
                setPartner(partner);
              }}
            />
          </Form.Item>

          <Alert
            message="MEIs sem um parceiro vinculado serão importados como usuários padrão."
            type="warning"
          />

          {notCreated.length ? (
            <div className="users-not-created">
              <h4>Usuários não importados</h4>

              <ul>
                {notCreated.map((user) => (
                  <li key={user}>{user}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </Form>
      </Modal>
    </div>
  );
}
