import React, { useState, useEffect, useReducer, useCallback } from "react";
import * as Queries from "./Queries";
import { useQuery, useLazyQuery, useMutation, gql } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Select,
	Form,
	Input,
	Popconfirm,
	notification,
	Drawer,
	Checkbox,
	DatePicker,
} from "antd";
import _ from "lodash";
import { useImmer } from "use-immer";
import { filter } from "lodash";
import debounce from "functions/debounce";
import dayjs from "dayjs";
import moment from "moment";

const { Content } = Layout;
const { Title } = Typography;

function Users(props) {
	const [filters, setFilters] = useImmer({});
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const [loadingPagination, setLoadingPagination] = useState(false);

	const [removePartnerAction] = useMutation(Queries.PARTNER_REMOVE);
	const [autoCompleteAction, autoCompleteData] = useLazyQuery(gql`
		query PartnersAutoComplete($term: String!) {
			PartnersAutoComplete(term: $term) {
				_id
				name
				company_name
				trade_name
			}
		}
	`);

	const onSearchPartner = debounce((e) => autoCompleteAction(e), 500);

	const [load, { loading, data, error, refetch }] = useLazyQuery(
		Queries.PARTNERS,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [filtersOpen, setFiltersOpen] = useState(false);

	// useEffect(() => {
	// 	load({
	// 		variables: {
	// 			page: 1,
	// 		},
	// 	});
	// }, []);

	useEffect(() => {
		const { user, ...currentFilters } = filters;

		load({
			variables: {
				page: 1,
				filters: currentFilters,
			},
		});
	}, [filters]);

	const removePartner = async (row) => {
		let res = await removePartnerAction({
			variables: {
				id: row.user_id,
			},
		});
		if (res.data.PartnerRemove) {
			notification.success({
				message: "Parceiro removido com sucesso!",
			});
			refetch();
		} else {
			notification.error({
				message: "Erro ao remover parceiro",
				description: "Por favor, tente novamente mais tarde.",
			});
		}
	};

	const columns = [
		{
			title: "Nome",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "MEIs",
			dataIndex: "meis",
			key: "meis",
		},
		{
			title: "CNPJ",
			dataIndex: "cnpj",
			key: "cnpj",
		},
		{
			title: "E-mail",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Telefone",
			dataIndex: "phone",
			key: "phone",
			render: (value, row) => {
				return value ? value : row.mobile ? row.mobile : "-";
			},
		},

		{
			title: "Data de Início",
			dataIndex: "date_start",
			key: "date_start",
			render: (value) => (value ? dayjs(value).format("DD/MM/YYYY") : "-"),
		},
		{
			title: "Plano",
			dataIndex: "plan_id",
			key: "plan_id",
			render: (plan_id) => {
				if (plan_id === 1) return "Mensal";
				if (plan_id === 2) return "Pré-pago";
				if (plan_id === 3) return "Premium";
				if (plan_id === 4) return "White Label";
				if (plan_id === 5) return "White Label Top";
				if (plan_id === 7) return "White Label Light";
				if (plan_id === 8) return "White Label Start";
				if (plan_id === 6) return "Outro";
			},
		},
		{
			title: "Ações",
			dataIndex: "",
			key: "x",
			width: 200,
			render: (value, row) => {
				return (
					<>
						<Button
							type="link"
							onClick={() => {
								props.history.push(`./partner/${value.user_id}`);
							}}
						>
							Editar
						</Button>
						<Popconfirm
							title="Deseja remover este parceiro?"
							onConfirm={() => {
								removePartner(row);
							}}
							onCancel={null}
							okText="Sim"
							cancelText="Não"
						>
							<Button type="link">Remover</Button>
						</Popconfirm>
					</>
				);
			},
		},
	];

	console.log(data);

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Parceiros</Title>
					<div>
						<Button
							type="primary"
							onClick={() => {
								props.history.push("/partner/new");
							}}
						>
							Novo Parceiro
						</Button>
						<Button
							type="default"
							onClick={() => {
								setFiltersOpen(true);
							}}
							style={{ marginLeft: 10 }}
						>
							Filtrar
						</Button>
					</div>
				</div>

				<Drawer
					title="Filtros"
					placement="right"
					onClose={() => {
						setFiltersOpen(false);
					}}
					visible={filtersOpen}
					width={450}
				>
					<Form layout="vertical">
						<Form.Item label="Nome / Nome Fantasia / Razão Social">
							{/* <Input
								onChange={(e) => {
									const { value } = e.target;
									setFilters((d) => {
										d.name = value !== "" ? value : null;
									});
								}}
							/> */}

							<Select
								showSearch
								labelInValue
								allowClear
								filterOption={false}
								onSearch={(term) => {
									onSearchPartner({
										variables: {
											term,
										},
									});
								}}
								onChange={(e) => {
									if (e) {
										setFilters((d) => {
											d.user_id = e.value;
											d.user = e;
										});
									} else {
										setFilters((d) => {
											d.user_id = null;
											d.user = null;
										});
									}
								}}
								value={filters?.user}
							>
								{autoCompleteData?.data?.PartnersAutoComplete?.length ? (
									<>
										{autoCompleteData?.data?.PartnersAutoComplete.map(
											(item, index) => {
												return (
													<Select.Option key={item._id} value={item._id}>
														<span>
															<strong>{item.name ?? "-"}</strong>
															<br />
															<span>
																Razao Social: {item.company_name ?? "-"}
															</span>
															<br />
															<span>
																Nome Fantasia: {item.trade_name ?? "-"}
															</span>
														</span>
													</Select.Option>
												);
											}
										)}
									</>
								) : null}
							</Select>
						</Form.Item>

						<Form.Item label="Plano">
							<Select
								value={filter.plan_id}
								onChange={(value) => {
									if (value === "") {
										setFilters((d) => {
											d.plan_id = null;
										});
									} else {
										setFilters((d) => {
											d.plan_id = value;
										});
									}
								}}
							>
								<Select.Option value={""}>Todos</Select.Option>
								<Select.Option value={1}>Mensal</Select.Option>
								<Select.Option value={2}>Pré-pago</Select.Option>
								<Select.Option value={3}>Premium</Select.Option>
								<Select.Option value={4}>White Label</Select.Option>
								<Select.Option value={5}>White Label Top</Select.Option>
								<Select.Option value={7}>White Label Light</Select.Option>
								<Select.Option value={8}>White Label Start</Select.Option>
								<Select.Option value={6}>Outro</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item label="E-mail">
							<Input
								onChange={(e) => {
									const { value } = e.target;
									setFilters((d) => {
										d.email = value !== "" ? value : null;
									});
								}}
							/>
						</Form.Item>
						<Form.Item label="Status">
							<Select
								defaultValue={""}
								onChange={(value) => {
									if (value !== "") {
										setFilters((draft) => {
											draft.status = value;
										});
									} else {
										setFilters((draft) => {
											draft.status = null;
										});
									}
								}}
							>
								<Select.Option value={""}>Qualquer</Select.Option>
								<Select.Option value={0}>Inativo</Select.Option>
								<Select.Option value={1}>Ativo</Select.Option>
								<Select.Option value={6}>Bloqueado</Select.Option>
							</Select>
						</Form.Item>

						<Form.Item label="Data Início Contrato">
							<DatePicker.RangePicker
								style={{
									width: "100%",
								}}
								format={"DD/MM/YYYY"}
								value={
									filters.contract_date
										? [
												moment(filters.contract_date[0]),
												moment(filters.contract_date[1]),
										  ]
										: []
								}
								onChange={(e) => {
									if (e?.length) {
										setFilters((draft) => {
											draft.contract_date = [
												e[0].toISOString(),
												e[1].toISOString(),
											];
										});
									} else {
										setFilters((draft) => {
											draft.contract_date = null;
										});
									}
								}}
							/>
						</Form.Item>

						<Form.Item label="">
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									gap: 10,
								}}
							>
								<Checkbox
									onChange={(e) => {
										setFilters((draft) => {
											draft.implanting = e.target.checked;
										});
									}}
									style={{
										marginLeft: 0,
									}}
									checked={filters?.implanting}
								>
									Implantação
								</Checkbox>

								<Checkbox
									onChange={(e) => {
										setFilters((draft) => {
											draft.appointments = e.target.checked;
										});
									}}
									style={{
										marginLeft: 0,
									}}
									checked={filters?.appointments}
								>
									Pendente Apontamento
								</Checkbox>
								<Checkbox
									onChange={(e) => {
										setFilters((draft) => {
											draft.webapp = e.target.checked;
										});
									}}
									style={{
										marginLeft: 0,
									}}
									checked={filters?.webapp}
								>
									Pendente Webapp
								</Checkbox>
								<Checkbox
									onChange={(e) => {
										setFilters((draft) => {
											draft.apps = e.target.checked;
										});
									}}
									style={{
										marginLeft: 0,
									}}
									checked={filters?.apps}
								>
									Pendente Apps
								</Checkbox>
								<Checkbox
									onChange={(e) => {
										setFilters((draft) => {
											draft.onboarding = e.target.checked;
										});
									}}
									style={{
										marginLeft: 0,
									}}
									checked={filters?.onboarding}
								>
									Pendente Onboarding
								</Checkbox>

								<Checkbox
									onChange={(e) => {
										setFilters((draft) => {
											draft.social_posts = e.target.checked;
										});
									}}
									style={{
										marginLeft: 0,
									}}
									checked={filters?.social_posts}
								>
									Posts Redes Sociais
								</Checkbox>
							</div>
						</Form.Item>

						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							{/* <Button
								onClick={() => {
									setFilters({
										status: "",
									});
								}}
							>
								Limpar
							</Button> */}
							{/* <Button
								type="primary"
								onClick={() => {
									const { user, ...currentFilters } = filters;

									load({
										variables: {
											page: 1,
											filters: currentFilters,
										},
									});
								}}
							>
								Buscar
							</Button> */}
						</div>
					</Form>
				</Drawer>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data?.Partners?.partners}
								columns={columns}
								loading={loadingPagination}
								pagination={{
									defaultCurrent: data.Partners.currentPage,
									total: data.Partners.total,
									showSizeChanger: false,
									defaultPageSize: 10,
								}}
								onChange={async (pagination) => {
									setLoadingPagination(true);
									await refetch({
										page: pagination.current,
										filters,
									});
									setLoadingPagination(false);
								}}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>
			</Content>
		</Layout>
	);
}

export default Users;
